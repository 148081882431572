import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { device } from "../utils/media-queries";

const NewsletterContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 980px;
  gap: 40px;
  padding-top: 220px;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 150px;

  @media ${device.desktop} {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 900px;
    margin-bottom: 240px;
  }

  @media ${device.laptop} {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 820px;
  }

  @media ${device.tablet} {
    padding-top: 20vh;
    justify-content: center;
    gap: 20px;
    margin-bottom: 90px;
  }

  @media ${device.mobile} {
    padding-top: 20vh;
  }
`;

export default function NewsletterContent() {
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
      (function(m, o, n, t, e, r, _) {
        m['__GetResponseAnalyticsObject'] = e;
        m[e] = m[e] || function() {
          (m[e].q = m[e].q || []).push(arguments);
        };
        r = o.createElement(n);
        _ = o.getElementsByTagName(n)[0];
        r.async = 1;
        r.src = t;
        r.setAttribute('crossorigin', 'use-credentials');
        _.parentNode.insertBefore(r, _);
      })(window, document, 'script', 'https://an.gr-wcon.com/script/c41de10f-b78e-4ef0-bcaa-0121fba9409c/ga.js', 'GrTracking');
    `}
        </script>
        <script
          type="text/javascript"
          src="https://app.getresponse.com/webconnect.js"
          async
        ></script>
      </Helmet>
      <NewsletterContentBlock>
        <getresponse-form
          form-id="3ca6a99a-6239-47ec-8cdc-a407b7be4a8f"
          e="0"
        ></getresponse-form>
      </NewsletterContentBlock>
    </>
  );
}
