import React from "react";
import styled from "styled-components";

import { device } from "../utils/media-queries";
import newsletterBg from "../images/newsletter-bg.svg";

const NewsLetterContainer = styled.div`
  width: 100%;
  background-color: #090909;
  background-image: url(${newsletterBg});
  background-repeat: no-repeat;

  background-size: 100% 100%;
  text-align: center;
  height: auto;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    justify-content: space-between;
    background-size: 150% 100%;
  }

  @media ${device.mobile} {
    justify-content: unset;
  }
`;

export default function NewsletterBlock({ children }) {
  return <NewsLetterContainer>{children}</NewsLetterContainer>;
}
