import React, { useState, useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { theme } from "../utils/theme";
import { _TOPBAR_HEIGHT_DESKTOP, _TOPBAR_HEIGHT_MOBILE } from "../constans";

import Topbar from "../molecules/topbar";
import Seo from "../layout/Seo";
import Footer from "../molecules/footer";
import NewsletterBlock from "../molecules/newsletter-block";
import NewsletterContent from "../molecules/newsletter-content";

const GlobalStyle = createGlobalStyle`
    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
    body {
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.fonts.main};
    }
`;

export default function Newsletter() {
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [scrollToOffset, setScrollToOffset] = useState(0);

  const isBrowser = typeof window !== "undefined";

  //Window width listener
  useEffect(() => {
    if (isBrowser) {
      setWidth(window.innerWidth);
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
      return () => {
        window.removeEventListener("resize", () => {});
      };
    }
    // eslint-disable-next-line
  }, []);

  //Window page Y Offset listener
  useEffect(() => {
    if (isBrowser) {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
      };
    }
    // eslint-disable-next-line
  }, []);

  //is Mobile?
  useEffect(() => {
    width <= 768 ? setMobile(true) : setMobile(false);
  }, [width]);

  //set Button ScrollTo offset prop
  useEffect(() => {
    if (mobile) {
      setScrollToOffset(_TOPBAR_HEIGHT_MOBILE * -1);
    } else {
      setScrollToOffset(_TOPBAR_HEIGHT_DESKTOP * -1);
    }
  }, [mobile]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Topbar
        scrollToOffset={scrollToOffset}
        width={width}
        mobile={mobile}
        offset={offset}
        type="newsletter"
      />
      <NewsletterBlock>
        <NewsletterContent />
        <Footer type="newsletter" />
      </NewsletterBlock>
    </ThemeProvider>
  );
}

export const Head = () => <Seo title="Blockbit.es | Newsletter" />;
